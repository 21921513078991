<template>
  <div>
    <c-table
      ref="evalTable"
      tableId="evalTable"
      title="평가항목"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :isExcelDown="false"
      :filtering="false"
      selection="multiple"
      :gridHeight="gridHeight"
      rowKey="trainingEvalItemId"
      :columns="grid.columns"
      :data="emerge.evalItems"
      :merge="grid.merge"
      @innerBtnClicked="innerBtnClicked"
      @table-data-change="datachange"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn 
              v-show="editable" 
              label="추가" icon="add" 
              @btnClicked="addRowEval" />
          <c-btn 
              v-show="editable && emerge.evalItems.length > 0" 
              label="삭제" 
              icon="remove" 
              :showLoading="false"
              @btnClicked="removeItem" />
          <c-btn 
            v-show="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="emerge.evalItems"
            mappingType="PUT"
            label="저장" 
            icon="save" 
            @beforeAction="saveInfo"
            @btnCallback="saveInfoCallback" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props, col }">
        <template v-if="col.name==='situationDevelopmentName'">
          <c-text-column
            :editable="editable"
            :col="col"
            :props="props"
            v-model="props.row['situationDevelopmentName']"
            @datachange="datachange(props)"
          />
          <div v-if="editable">
            <q-btn-group outline class="ColumInnerBtnGroup">
              <q-btn
                icon="add"
                color="light-blue"
                text-color="white"
                class="ColumInnerBtn"
                align="center"
                @click.prevent="innerBtnClicked(col, props)">
                <q-tooltip>
                  {{ $language('상황전개별 평가항목 추가') }}
                </q-tooltip>
              </q-btn>
            </q-btn-group>
          </div>
        </template>
      </template>
    </c-table>
  </div>
</template>

<script>
import { uid } from 'quasar';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'law-register',
  props: {
    emerge: {
      type: Object,
      default: () => ({
        trainingScenarioId: '',
        deptCd: '',
        trainingScenarioName: '',
        emergencyPlanName: '',
        plantCd: '',
        useFlag: '',
        scenarios: [],
        deleteScenarios: [],
        evalItems: [],
        deleteEvalItems: [],
      }),
    },
    contentHeight: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchParam: {
        lawId: null,
        useFlag: 'Y',
      },
      grid: {
        merge: [
          // { index: 0, colName: 'situationDevelopmentName' },
          // { index: 1, colName: 'trainingEvalItemId' },
        ],
        columns: [
          
        ],
        data: [],
      },
      saveData: [],
      isSave: false,
      editable: true,
      deleteItemUrl: '',
      saveUrl: '',
      popupOptions: {
        isFull: false,
        width: '40%',
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      let height = this.contentHeight - 100;
      if (height < 450) {
        height = 450;
      }
      return String(height) + 'px';
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sop.eap.eval.insert.url;
      this.deleteItemUrl = transactionConfig.sop.eap.eval.delete.url;
      // code setting
      // list setting
      this.setHeader();
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    getDetail() {
      this.$emit("getDetail");
    },
    setHeader() {
      this.grid.columns = [
        {
          required: true,
          name: 'situationDevelopmentName',
          field: 'situationDevelopmentName',
          label: '상황전개',
          align: 'center',
          style: 'width:300px',
          type: 'custom',
          sortable: false,
        },
        {
          required: true,
          name: 'evalItems',
          field: 'evalItems',
          label: '평가항목',
          align: 'left',
          style: 'width:1000px',
          type: 'text',
          sortable: false,
        },
        { 
          required: true,
          name: 'sortOrder',
          field: 'sortOrder',
          label: '정렬순서',
          align: 'center',
          sortable: true,
          style: 'width:80px',
          type: 'number',
        },
      ]
    },
    innerBtnClicked(col, props) {
      if (col.name === 'situationDevelopmentName') {
        // 상황전개
        this.emerge.evalItems.splice(props.rowIndex + 1, 0, {
          trainingScenarioId: this.emerge.trainingScenarioId,
          situationDevelopmentName: props.row.situationDevelopmentName,
          trainingEvalItemId: uid(),
          evalItems: '',
          editFlag: 'C',
          regUserId: this.$store.getters.user.userId,
          sortOrder: 0,
        })
      }
    },
    saveInfo() {
      if (this.emerge.evalItems) {
        this.saveUrl = transactionConfig.sop.eap.eval.update.url;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.eap.eval.insert.url;
        this.saveType = 'POST';
      }
      if (this.$comm.validTable(this.grid.columns, this.emerge.evalItems)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?', 
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.emerge.regUserId = this.$store.getters.user.userId
            this.emerge.chgUserId = this.$store.getters.user.userId

            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveInfoCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit("getDetail");
    },
    addRowEval() {
      this.emerge.evalItems.push({
        trainingScenarioId: this.emerge.trainingScenarioId,
        trainingEvalItemId: uid(),
        situationDevelopmentName: '',
        evalItems: '',
        sortOrder: 0,
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    removeItem() {
      let selectData = this.$refs['evalTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteItemUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData),
          };
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');

            this.$_.forEach(selectData, item => {
              this.emerge.evalItems = this.$_.reject(this.emerge.evalItems, item);
            })
            this.$refs['evalTable'].$refs['compo-table'].clearSelection();
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
        });
      }
    },
  }
};
</script>
